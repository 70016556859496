// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeaderLogo from '../../components/BestSchoolsAwards/HeaderLogo/TideHeaderLogo'
import Nav from '../../components/BestSchoolsAwards/Nav/Nav'
import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'gatsby'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WIBAboutTide = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <Nav site="tide" theme="dark" />
      <TideHeaderLogo />
      <BSAContent>
        <h1>About Tide</h1>
        <p>
          Founded in 2015 and launched in 2017, Tide is the leading business
          financial platform in the UK with over 500,000 SME members. We help
          business owners save time (and money) in the running of their
          businesses by not only offering free and easy to use business current
          accounts and related banking services, but also a comprehensive set of
          administrative solutions from invoicing to accounting.
        </p>
        <p>
          At Tide, we’re firm believers in the boundless power of women in
          business and their incredible potential to shape the future. Back in
          2019, Tide set a target to welcome 100,000 female-led businesses in
          the UK by the end of 2023, and support them in starting and running
          their own business – an initiative in response to the Alison Rose
          Review of Female Entrepreneurship (March 2019) which found that only
          32% of UK entrepreneurs are women.
        </p>
        <p>
          In June 2023, we had already hit the ambitious target of 100,000 new
          female business owners, ten months early, and that achievement has
          only fuelled our passion and determination to support even more
          aspiring women in entrepreneurship.
        </p>
        <p>
          Tide has doubled the target and wants to help another 100,000 women in
          business start and thrive by the end of 2027. As the first fintech
          company to support female entrepreneurs on such a large scale, Tide is
          dedicated to promoting gender equality and removing obstacles every
          step of their way.
        </p>
        <p>
          A recent survey of 2,000 Tide members showed that more than half (53%)
          of women in the UK find it challenging to start their own business and
          the number one barrier holding more than half of women back from
          starting their own business is access to funding. 50% of our survey
          respondents said they had been rejected after applying for a loan or
          investment to fund their new business.
        </p>
        <p className={ContentStyles.Subtitle}>
          <strong>SHE MEANS BUSINESS Award – Tide x Muddy Stilettos</strong>
        </p>
        <p>
          The SHE MEANS BUSINESS campaign on Muddy Stilettos, sponsored by Tide,
          has the objective of helping female entrepreneurs with funding and
          support for their business ventures. The lucky winner will take home
          an investment of £10,000 into their business idea or existing
          business, and all participants and finalists will be included in a
          group of like-minded female entrepreneurs.
        </p>
        {/* <p style={{ textAlign: 'center' }}>
          <Link
            to={`/women-in-business/dashboard/`}
            className={`${ContentStyles.RegisterInterestButton} ${ContentStyles.Pink}`}
          >
            Get Started
          </Link>
        </p> */}
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WIBAboutTide
